import React from 'react';
import img from '../images/dog4.png';

const Metodologie = () => {
    return (
        <div className="bg-gray-100">
                <div className="m-auto max-w-6xl h-5/6 py-8" id='metodologie' >

                    <div className="flex flex-col lg:flex-row justify-between lg:text-left" data-aos="fade-up">
                       
                        <div className="lex-col md:p-14  my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8">
                         
                        <h3 className="text-3xl  text-orange-400 font-bold">Metodología</h3>
                            <div>
                                <p className='my-3 text-lg text-gray-800'>
                                    Durante el proceso de la terapia, destacamos las siguientes fases para el desarrollo de los cambios necesarios en los animales: 
                                </p>
                                <p className="font-semibold text-lg">Valoración y diagnóstico</p>
                                <p>Recogemos toda la información necesaria para una valoración con un especialista en conducta canina:</p>
                                <ul className="pl-7 list-disc mt-3">
                                    <li><b>Primer contacto:</b> Identificamos las dificultades en casa y enviamos nuestro dossier de servicios. Tras aceptar los precios, completáis el formulario de solicitud.</li>
                                    <li><b>Recepción de solicitud:</b> Coordinamos una cita según tu disponibilidad, ya sea en casa, nuestro centro canino o un lugar adecuado</li>
                                </ul>
                            </div>
                            
                            <div className="mt-5">
                                <p className="font-semibold text-lg">Diseño y planificación</p>
                                <p className='my-3 text-lg text-gray-800'>Sesión de valoración: Durante esta primera sesión, nuestros educadores de perros a domicilio se centrarán en:</p>
                                <ul className="pl-7 list-disc mt-3">
                                    <li>Valorar de los comportamientos del perro.</li>
                                    <li>Determinar las funciones ejecutivas y capacidades del perro.</li>
                                    <li>Evaluar el ambiente en el que se encuentra en su día a día.</li>
                                    <li>Estimar su grado de bienestar y sus necesidades.</li>
                                    <li>Entender las dificultades, desde el punto de vista del perro.</li>
                                    <li>Compartir las necesidades de la familia.</li>
                                    <li>Buscar puntos de encuentro y desencuentro entre el perro y el resto de la familia.</li>
                                    <li>Exponer los objetivos a trabajar.</li>
                                    <li>Valoración y capacitación de las habilidades y destrezas.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex-col md:p-14  my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <p className='my-3 text-lg text-gray-800'>En la sesión de valoración, evaluamos el comportamiento, capacidades y bienestar del perro, así como su entorno y las necesidades de la familia, para identificar objetivos y puntos de trabajo. Tras la sesión, recibirás un informe con objetivos generales y mensuales, pautas iniciales y un presupuesto personalizado, adaptado al perro y su entorno, con enfoque en una terapia integral y evaluación continua.</p>
                            <div>
                                <p className="font-semibold text-lg">Evaluación continua</p>
                                <p className='my-3 text-lg text-gray-800'>
                                    Somos un equipo multidisciplinario conformado por profesionales de diferentes áreas, las cuales destacan: 
                                </p>
                                <p className='my-3 text-lg text-gray-800'>
                                     Realizamos una evaluación continua para asegurar el progreso hacia los objetivos, ajustando el plan según sea necesario. Evaluamos cada sesión y revisamos los objetivos mensuales, manteniendo una comunicación constante contigo. Los casos se analizan en equipo para garantizar un servicio eficiente y de calidad, priorizando la satisfacción del cliente y las necesidades de las familias multiespecie.
                                </p>
                                 <div>
                                        <img alt="Entranamiento de mascotas" className="rounded-t float-right" src={img} />
                                 </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Metodologie;