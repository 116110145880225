const data = [
    {
        id: "1",
        name: "NALA",
        raza: "BULLDOG FRANCES",
        gender: "HEMBRA",
        chip: "01239481293",
        photo: "01239481293.png",
    },
]

const getInfoCertificate = (id, name) => {
    const dog = data.find(d => d.id === id && d.name.toLowerCase() === name.toLowerCase());
    return dog ? dog : false;
}

export {
    getInfoCertificate
}