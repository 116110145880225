import React from 'react';
import img1 from '../images/cer1.png';
import img2 from '../images/cer2.png';

const Want = () => {
    return (
        <>
                <div className="m-auto max-w-6xl h-5/6 py-8" id='about' >

                    <div className="flex flex-col lg:flex-row justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col md:p-14  my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-2/3 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-orange-400 font-bold">Quiero certificar a mi perro</h3>
                            <div>
                                <p className='my-3 text-lg text-gray-800'>
                                  Los perros entrenados y certificados como "de terapia" son seleccionados cuidadosamente a partir de evaluaciones que consideran su temperamento, habilidades innatas y características específicas de su raza. Estos procesos aseguran que los canes cuenten con el perfil adecuado para desempeñar tareas especializadas. Desde edades tempranas, son sometidos a programas de socialización, estimulación y entrenamiento que les preparan para su rol. Esto significa que no todos los perros pueden ser aptos para terapia. No obstante, existen casos en los que perros adultos con las cualidades necesarias pueden ser entrenados y certificados para cumplir estas funciones de manera efectiva.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 flex flex-row lg:mx-4 items-center">
                            <div>
                                <img alt="assistance dogs" className="w-[210px]" src={img1} />
                            </div>
                            <div>
                                <img alt="tacop chile"  className="w-[210px]" src={img2} />
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Want;