import React from 'react';
import img from '../images/dog3.png';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl h-5/6 py-8" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/3 flex flex-col lg:mx-4 justify-end">
                            <img alt="Entranamiento de mascotas" className="rounded-t float-right" src={img} />
                        </div>
                        <div className="flex-col md:p-14  my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-2/3 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-orange-400 font-bold">¿Quienes somos?</h3>
                            <div>
                                <p className='my-3 text-lg text-gray-800'>
                                    Somos un equipo multidisciplinario conformado por profesionales de diferentes áreas, las cuales destacan: 
                                </p>
                                <ul className="pl-7 list-disc">
                                        <li>Profesionales especialistas en Obediencia.</li>
                                        <li>Profesionales especialistas en Protección y Seguridad.</li>
                                        <li>Criadores especializados en razas de trabajo.</li>
                                </ul>
                            </div>
                            
                            <div className="mt-5">
                                <h3 className="text-2xl text-orange-400 font-bold">Nuestra misión</h3>
                                <p className='my-3 text-lg text-gray-800'>Ser líderes en educación canina, comprometidos con la responsabilidad y la empatía. Nos enfocamos en el bienestar y desarrollo de perros y sus dueños, ofreciendo soluciones de seguridad con canes entrenados. Fortalecemos los lazos mediante confianza y comunicación efectiva, creando un entorno de aprendizaje personalizado para garantizar protección y tranquilidad.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;